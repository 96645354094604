import type {ReactElement} from 'react';

import './CarouselItem.css';

export default function CarouselItem(props: {
    icon: ReactElement;
    text: string;
    onClick: () => void;
}): ReactElement {
    return (
        <div className="carousel-item-container">
            <div className="carousel-item" onClick={props.onClick}>
                <div className="icon">{props.icon}</div>
                <div className="text">{props.text}</div>
            </div>
        </div>
    );
}
