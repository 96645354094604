import type {ReactElement} from 'react';
import {Routes, Route} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type SingleUserNoAuth from '@refinio/one.models/lib/models/Authenticator/SingleUserNoAuth.js';
import type ConnectionsModel from '@refinio/one.models/lib/models/ConnectionsModel.js';
import {APP_BAR_MODE} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';
import type ChannelManager from '@refinio/one.models/lib/models/ChannelManager.js';

import PageNotFound from '@/components/PageNotFound.js';
import AppBarSettings from '@/components/appBar/AppBarSettings.js';
import InstancesSettingsView from './InstancesSettingsView.js';
import DetailedInstanceSettingsView from './instanceId/DetailedInstanceSettingsView.js';

export default function SettingsRouter(props: {
    leuteModel: LeuteModel;
    one: SingleUserNoAuth;
    connections: ConnectionsModel;
    channelManager: ChannelManager;
}): ReactElement {
    const {leuteModel, one, connections} = props;
    const i18n = useTranslation();

    return (
        <Routes>
            <Route
                index
                element={
                    <>
                        <AppBarSettings
                            mode={APP_BAR_MODE.BROWSE}
                            title={i18n.t('menu.settings')}
                        />
                        <InstancesSettingsView leuteModel={leuteModel} />
                    </>
                }
            />
            <Route
                path={':personId'}
                element={
                    <DetailedInstanceSettingsView
                        leuteModel={leuteModel}
                        one={one}
                        connectionsModel={connections}
                        channelManager={props.channelManager}
                    />
                }
            />
            <Route element={<PageNotFound />} />
        </Routes>
    );
}
