import {useTheme} from '@mui/material/styles';
import {useState, type ReactElement, useCallback} from 'react';
import ReactMarkdown from 'react-markdown';

import AppBarFullScreenPopup from '@refinio/one.ui/lib/ui/components/appBar/AppBarFullScreenPopup.js';
import {APP_BAR_MODE} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';

import ToggleDetails from '@/root/chat/attachmentViews/utils/ToggleDetails.js';
import CertificateDetails from '@/root/chat/attachmentViews/utils/CertificateDetails.js';

import './ChatBubble.css';
import Button from '@mui/material/Button/Button.js';
import {useTranslation} from 'react-i18next';

/**
 * Helper function for format hh:mm DD.MM.YYYY
 * @param date
 * @returns
 */
function getCustomDateString(date: Date): string {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${hours}:${minutes} ${day}.${month}.${year}`;
}

/**
 *
 * @param props.date
 * @param props.className used to set bubble position
 * @param props.sender Optional.
 * @param props.message Optional.
 * @param props.attachmentsViews Optional.
 * @returns
 */
export default function ChatBubble(props: {
    date: Date;
    className: string;
    sender?: string;
    message?: string;
    attachmentsViews?: ReactElement[];
    onMessageDetailsClicked?: () => void;
}): ReactElement {
    const i18n = useTranslation();


    return (
        <div className={`fade-in chat-bubble ${props.className}`}>
            <div className="chat-bubble-content">
                <div className="chat-message-info-container">
                    {props.sender ? (
                        <>
                            <div className="chat-message-name-date-container">
                                <div className="chat-message-name">{props.sender}</div>
                                <div className="chat-message-date">
                                    {getCustomDateString(props.date)}{' '}
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="chat-message-date">{getCustomDateString(props.date)}</div>
                    )}
                </div>
                {props.message && (
                    <div className="chat-bubble-content-message">
                        <Message>{props.message}</Message>
                    </div>
                )}
                {(!props.attachmentsViews || props.attachmentsViews.length === 0) &&
                    props.onMessageDetailsClicked && (
                        <ToggleDetails>
                            <div className="message-certificates-container">
                                <Button onClick={props.onMessageDetailsClicked}>{i18n.t('chat.viewCertificates')}</Button>
                            </div>
                        </ToggleDetails>
                    )}
                {props.attachmentsViews && (
                    <div className="chat-bubble-content-attachments">{props.attachmentsViews}</div>
                )}
            </div>
        </div>
    );
}

/**
 * Helper for rendering message content
 * @param props.children message content
 * @returns
 */
function Message(props: {children?: string}): ReactElement {
    const [isOpen, setOpen] = useState<boolean>(false);
    const toggleOpen = useCallback(() => setOpen(o => !o), [setOpen]);
    const theme = useTheme();

    if (!props.children) return <></>;
    return (
        <>
            <div onClick={toggleOpen} className="non-fullscreen">
                <ReactMarkdown linkTarget="_blank" disallowedElements={['script', 'iframe']}>
                    {props.children}
                </ReactMarkdown>
            </div>
            {isOpen && (
                <AppBarFullScreenPopup
                    background={theme.palette.background.default}
                    title=""
                    mode={APP_BAR_MODE.CHEVRON}
                    onClose={toggleOpen}
                >
                    <ReactMarkdown linkTarget="_blank" disallowedElements={['script', 'iframe']}>
                        {props.children}
                    </ReactMarkdown>
                </AppBarFullScreenPopup>
            )}
        </>
    );
}
