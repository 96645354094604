import {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import type {ReactElement} from 'react-markdown/lib/react-markdown.js';

import Button from '@mui/material/Button/Button.js';

export default function CertificateDetails(props: {
    certificateView: (onClose: () => void) => ReactElement;
}): ReactElement {
    const i18n = useTranslation();
    const [openedCertificates, setOpenedCertificates] = useState<boolean>(false);
    const closeCertificates = useCallback(
        () => setOpenedCertificates(false),
        [setOpenedCertificates]
    );
    const openCertificates = useCallback(
        () => setOpenedCertificates(true),
        [setOpenedCertificates]
    );

    return (
        <div className="message-certificates-container">
            <Button onClick={openCertificates}>{i18n.t('chat.viewCertificates')}</Button>
            {openedCertificates && props.certificateView(closeCertificates)}
        </div>
    );
}
