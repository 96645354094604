import React, {type ReactElement} from 'react';

import './CarouselList.css';

export default function CarouselList(props: {
    children: ReactElement[];
    title?: string;
    className?: string;
}): ReactElement {
    return (
        <div className={`carousel-list-container${props.className ? ` ${props.className}` : ''}`}>
            {props.title && <div className="carousel-list-title">{props.title}</div>}
            <div className="carousel-list">{props.children}</div>
        </div>
    );
}
