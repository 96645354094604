import {type ReactElement, useRef, useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import CircularProgress from '@mui/material/CircularProgress/CircularProgress.js';
import Typography from '@mui/material/Typography/Typography.js';

import type ChannelManager from '@refinio/one.models/lib/models/ChannelManager.js';
import type JournalModel from '@refinio/one.models/lib/models/JournalModel.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type TopicModel from '@refinio/one.models/lib/models/Chat/TopicModel.js';
import DocumentModel from '@refinio/one.models/lib/models/DocumentModel.js';
import type QuestionnaireModel from '@refinio/one.models/lib/models/QuestionnaireModel.js';
import type Notifications from '@refinio/one.models/lib/models/Notifications.js';
import CollapsibleComponent from '@refinio/one.ui/lib/ui/components/collapsibleComponent/CollapsibleComponent.js';
import Journal, {LIST_SEPARATION} from '@refinio/one.ui/lib/ui/views/journal/Journal.js';

import AvatarPreview from '@/components/avatarPreview/AvatarPreview.js';
import {MENU_ENTRY} from '@/components/popupMenu/PopupMenu.js';
import {useSomeonePreviewFromSomeoneIdParam} from '@/hooks/contact/someoneHooks.js';
import {useEventTypes} from '@/hooks/journal/hooks.js';
import type {SomeonePreview} from '@/root/contacts/LeuteView.js';
import type {QuestionnaireMenu} from '@/components/carousel/CarouselMenu';
import CarouselMenu from '@/components/carousel/CarouselMenu';

import './PatientView.css';

async function uploadDocuments(
    channelManager: ChannelManager,
    documentModel: DocumentModel,
    patient: SomeonePreview,
    files: FileList | null
): Promise<void> {
    if (!files) {
        return;
    }
    if (
        files.length > 0 &&
        !(await channelManager.hasChannel(DocumentModel.channelId, patient.mainProfile.personId))
    ) {
        await channelManager.createChannel(DocumentModel.channelId, patient.mainProfile.personId);
    }

    for (const file of files) {
        await documentModel.addDocument(
            await file.arrayBuffer(),
            file.type,
            file.name,
            DocumentModel.channelId,
            patient.mainProfile.personId
        );
    }
}

const questionnaireMenu: QuestionnaireMenu[] = [
    {
        entry: MENU_ENTRY.GeneralFeedbackQuestionnaire,
        name: 'general_feedback_edda'
    },
    {entry: MENU_ENTRY.WhpQuestionnaire, name: 'WHP Follow Up Visit From 1'},
    {
        entry: MENU_ENTRY.CervicalCancerScreeningAndTreatmentRegisterQuestionnaire,
        name: 'cervical_cancer_screeninc_and_treatment_register'
    },
    {
        entry: MENU_ENTRY.IFCPC_colposcopic_terminology_cervix,
        name: '2011_IFCPC_colposcopic_terminology_cervix'
    },
    {
        entry: MENU_ENTRY.ColposcopyExaminationRecord,
        name: 'colposcopy_examination_record'
    },
    {
        entry: MENU_ENTRY.SpecificFeedbackQuestionnaire,
        name: 'specific_feedback_edda'
    }
];

export default function PatientView(props: {
    leuteModel: LeuteModel;
    topicModel: TopicModel;
    notifications: Notifications;
    journalModel: JournalModel;
    questionnaireModel: QuestionnaireModel;
    documentModel: DocumentModel;
    channelManager: ChannelManager;
}): ReactElement {
    const i18n = useTranslation();
    const patient = useSomeonePreviewFromSomeoneIdParam(props.leuteModel);
    const eventTypes = useEventTypes(props.questionnaireModel);
    const inputFileRef = useRef<HTMLInputElement>(null);
    const [name, setName] = useState<string | undefined>(undefined);
    const [birthday, setBirthday] = useState<string | undefined>(undefined);
    const [email, setEmail] = useState<string | undefined>(undefined);

    useEffect(() => {
        function getName(): void {
            if (name !== undefined) {
                return;
            }
            if (!patient) {
                return;
            }

            setName(props.leuteModel.getPersonName(patient.mainProfile.personId));
        }
        getName();
        return props.leuteModel.onProfileUpdate(getName);
    }, [name, patient, props.leuteModel]);

    useEffect(() => {
        async function getBirthDay(timeOfEarliestChange?: Date): Promise<void> {
            if (birthday !== undefined || email !== undefined) {
                return;
            }
            if (!patient) {
                return;
            }

            let hasBirthday = false;
            let hasEmail = false;
            for await (const questionnaireResponseObjectData of props.questionnaireModel.responsesIterator(
                {owner: patient.mainProfile.personId, from: timeOfEarliestChange}
            )) {
                const questionnaireResponses = questionnaireResponseObjectData.data;
                if (questionnaireResponses.response.length <= 0) {
                    continue;
                }

                const response = questionnaireResponses.response[0];

                if (response.questionnaire === 'http://refinio.one/questionaire/Onboarding') {
                    const nameA = response.item.find(i => i.linkId === 'name');
                    const birthdayA = response.item.find(i => i.linkId === 'birthdate');
                    if (nameA && birthdayA) {
                        setName(nameA.answer[0].valueString);
                        setBirthday(birthdayA.answer[0].valueDate);
                        break;
                    }
                    const birthdayResponse = response.item.find(i => i.linkId === 'birthdate');
                    const emailResponse = response.item.find(i => i.linkId === 'email');
                    if (birthdayResponse) {
                        setBirthday(birthdayResponse.answer[0].valueDate);
                        hasBirthday = true;
                    }
                    if (emailResponse) {
                        setEmail(emailResponse.answer[0].valueString);
                        hasEmail = true;
                    }
                }

                if (hasBirthday && hasEmail) {
                    break;
                }
            }
        }
        getBirthDay().catch(console.error);
        return props.questionnaireModel.onUpdated(getBirthDay);
    }, [birthday, email, patient, props.leuteModel, props.questionnaireModel]);

    return (
        <div className="patient-view-container">
            <div className="patient-view-content">
                {patient && (
                    <>
                        <div className="patient-view-boxes">
                            <div className="patient-view-box patient-view-avatar">
                                <AvatarPreview src={patient.avatar} />
                            </div>
                            <div className="patient-view-box patient-view-info">
                                {!name && !birthday && !email ? (
                                    <CircularProgress />
                                ) : (
                                    <>
                                        {name && (
                                            <>
                                                <Typography className="title">
                                                    {i18n.t('malawi_demo.name')}
                                                </Typography>
                                                <Typography className="value">{name}</Typography>
                                            </>
                                        )}
                                        {birthday && (
                                            <>
                                                <Typography className="title">
                                                    {i18n.t('malawi_demo.birthday')}
                                                </Typography>
                                                <Typography className="value">
                                                    {birthday}
                                                </Typography>
                                            </>
                                        )}
                                        {email && (
                                            <>
                                                <Typography className="title">
                                                    {i18n.t('malawi_demo.email')}
                                                </Typography>
                                                <Typography className="value">{email}</Typography>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="patient-carousel-menu">
                            <CarouselMenu
                                onDocumentSubmit={async file =>
                                    props.documentModel.addDocument(
                                        await file.arrayBuffer(),
                                        file.type,
                                        file.name,
                                        DocumentModel.channelId,
                                        patient.mainProfile.personId
                                    )
                                }
                                dataOwner={patient.mainProfile.personId}
                                title={i18n.t('home.carouselTitle')}
                                questionnaireMenu={questionnaireMenu}
                            />
                        </div>
                        <div className="patient-view-data">
                            <CollapsibleComponent
                                headline={<>{i18n.t('malawi_demo.title.data')}</>}
                                content={
                                    <>
                                        {eventTypes === undefined ? (
                                            <CircularProgress />
                                        ) : (
                                            <Journal
                                                journalModel={props.journalModel}
                                                eventTypes={eventTypes}
                                                enableFiltering={false}
                                                queryOptions={{owner: patient.mainProfile.personId}}
                                                listSeparation={LIST_SEPARATION.none}
                                                leuteModel={props.leuteModel}
                                            />
                                        )}
                                    </>
                                }
                            />
                            <input
                                capture="environment"
                                type="file"
                                ref={inputFileRef}
                                style={{display: 'none'}}
                                onChange={async e =>
                                    await uploadDocuments(
                                        props.channelManager,
                                        props.documentModel,
                                        patient,
                                        e.target.files
                                    )
                                }
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}
