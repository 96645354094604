import type {ReactElement} from 'react';
import {useContext, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import type {Group} from '@refinio/one.core/lib/recipes.js';
import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type GroupModel from '@refinio/one.models/lib/models/Leute/GroupModel.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import {APP_BAR_MODE, AppBarContext} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';

import {MENU_ENTRY} from '@/components/popupMenu/PopupMenu.js';
import BackButton from '@/components/backButton/BackButton.js';
import ContactCard from '@/components/card/ContactCard.js';
import TextFieldEntry from '@/components/textFieldEntry/TextFieldEntry.js';
import {MenuContext} from '@/context/MenuContext.js';
import {useGroup} from '@/hooks/contact/groupHooks.js';
import i18n from '@/i18n.js';
import GroupMembersList from '@/root/group/common/GroupMembersList.js';
import {getURL} from '@/utils/Utils.js';

import './Group.css';
import {useNavigateBack} from '@/hooks/navigation';

/**
 * Group Main View - shows the details & the members
 * @param props.profiles
 * @param props.leuteModel
 * @param props.backButtonCallback
 * @constructor
 */
export default function GroupView(props: {leuteModel: LeuteModel}): ReactElement {
    const {setContextValue} = useContext(AppBarContext);
    const {setMenuEntries, selectMenuEntry} = useContext(MenuContext);
    const params = useParams<{groupId: SHA256IdHash<Group>}>();
    const group = useGroup(params.groupId);
    const navigate = useNavigate();
    const goBack = useNavigateBack();

    /**
     * Each time the user loads this component, update the app bar context
     */
    useEffect(() => {
        setMenuEntries([MENU_ENTRY.EDIT_GROUP_MEMBERS, MENU_ENTRY.EDIT_GROUP_DETAILS]);
        selectMenuEntry(() => setEditingOption);
        setContextValue(contextValue => ({
            ...contextValue,
            mode: APP_BAR_MODE.CHEVRON_VIEW,
            title: 'contacts:group',
            leftBtnCallback: goBack
        }));
        // mount only
    }, []);

    /**
     * Set the right editing option based on {@link MENU_ENTRY}
     * @param selectedMenuEntry
     */
    function setEditingOption(selectedMenuEntry: string): void {
        switch (selectedMenuEntry) {
            case MENU_ENTRY.EDIT_GROUP_MEMBERS:
                navigate(`/group/${params.groupId}/edit/members`);
                break;
            case MENU_ENTRY.EDIT_GROUP_DETAILS:
                navigate(`/group/${params.groupId}/edit/general`);
                break;
        }
    }

    function renderGroupDetails(groupModel: GroupModel): ReactElement {
        return (
            <div className="cnt-someone-details">
                <div className="cnt-title">{i18n.t('leute.group.title')}</div>
                <ContactCard
                    content={
                        <div className="disabled">
                            {groupModel.picture !== undefined ? (
                                <img
                                    className="preview"
                                    src={getURL(groupModel.picture)}
                                    alt="avatar-preview"
                                />
                            ) : (
                                <div className="cnt-empty-image" />
                            )}
                            <div className="trust-section">
                                <TextFieldEntry
                                    label={i18n.t('leute.group.groupFields.name')}
                                    onDone={() => {}}
                                    value={
                                        i18n.t(`leute.group.names.${groupModel.name}`) ===
                                        `leute.group.names.${groupModel.name}`
                                            ? groupModel.name
                                            : i18n.t(`leute.group.names.${groupModel.name}`)
                                    }
                                    disableUnderline={true}
                                />
                            </div>
                        </div>
                    }
                />
                <GroupMembersList leuteModel={props.leuteModel} group={groupModel} />
            </div>
        );
    }

    /**
     * Renders the right view based on the selected editting option. Default is {@link renderGroupDetails}
     */
    function renderView(): ReactElement | null {
        if (group === undefined) {
            return null;
        }

        return renderGroupDetails(group);
    }

    return <>{renderView()}</>;
}
