import {useTranslation} from 'react-i18next';
import type {ReactElement} from 'react-markdown/lib/react-markdown.js';

import './OwnSignKeys.css';

export default function OwnSignKeys(_props: {}): ReactElement {
    const i18n = useTranslation();

    return (
        <div className="own-sign-keys-container">
            <div className="key-container first">
                <div className="title">{i18n.t('leute.signKey.publicKey')}</div>
                <div className="value">{`<public sign key placeholder>`}</div>
            </div>
            <div className="key-container last">
                <div className="title">{i18n.t('leute.signKey.privateKey')}</div>
                <div className="value">{`<private sign key placeholder>`}</div>
            </div>
        </div>
    );
}
