import type {SHA256Hash, SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {BLOB, Person} from '@refinio/one.core/lib/recipes.js';
import {createFileWriteStream} from '@refinio/one.core/lib/system/storage-streams.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';

/**
 * Used to save the collected information from the onboarding process in the main profile.
 *
 * TODO device name is ignored atm
 * @param leuteModel
 * @param name
 * @param email
 * @param avatar
 */
export async function updateMainUser(
    leuteModel: LeuteModel,
    name: string,
    email?: string | undefined,
    avatar?: ArrayBuffer | undefined
): Promise<SHA256IdHash<Person>> {
    const me = await leuteModel.me();
    const mainProfile = await me.mainProfile();
    mainProfile.personDescriptions.push({$type$: 'PersonName', name});

    if (email !== undefined && email !== '') {
        mainProfile.communicationEndpoints.push({
            $type$: 'Email',
            email: email
        });
    }

    if (avatar !== undefined) {
        const imageHash = await saveAvatarAsBLOB(avatar);
        mainProfile.personDescriptions.push({
            $type$: 'ProfileImage',
            image: imageHash
        });
    }

    await mainProfile.saveAndLoad();
    return mainProfile.personId;
}

/**
 * Saving the avatar in ONE as a BLOB and returning the reference for it.
 *
 * @param avatar - the avatar that is saved in ONE as a BLOB.
 * @returns - The reference to the saved BLOB.
 */
export async function saveAvatarAsBLOB(avatar: ArrayBuffer): Promise<SHA256Hash<BLOB>> {
    const stream = createFileWriteStream();
    stream.write(avatar);

    const blob = await stream.end();

    return blob.hash;
}
