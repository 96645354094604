import {useEffect, useState, type ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {isIPhone13, isMobile} from 'react-device-detect';
import {useZxing, type Result} from 'react-zxing';

import CircularProgress from '@mui/material/CircularProgress/CircularProgress.js';
import IconButton from '@mui/material/IconButton/IconButton.js';
import FlipCameraIosOutlined from '@mui/icons-material/FlipCameraIosOutlined.js';

import type ConnectionsModel from '@refinio/one.models/lib/models/ConnectionsModel.js';
import type IoMManager from '@refinio/one.models/lib/models/IoM/IoMManager.js';
import {
    useVideoDeviceDirectionInformation,
    useVideoDeviceSelector,
    useVideoDevices
} from '@refinio/one.ui/lib/ui/hooks/video';

import {
    NOTIFICATION,
    useNotificationContext
} from '@/components/notification/SnackbarNotification.js';
import {useNavigateBack} from '@/hooks/navigation.js';
import {useParing} from '@/utils/pairing.js';

import './QrScanner.css';

export default function QrScanner(props: {
    connectionsModel: ConnectionsModel;
    iomManager: IoMManager;
    failRoute?: string;
}): ReactElement {
    const {setNotificationMessage, setNotificationType} = useNotificationContext();
    const i18n = useTranslation();

    const videoDevices = useVideoDevices();
    const [selectedVideoDevice, changeVideoDeviceId, videoDeviceSelector] = useVideoDeviceSelector(
        videoDevices,
        {
            className: 'camera-capture-desktop-switch'
        }
    );
    const videoDeviceInformation = useVideoDeviceDirectionInformation(
        videoDevices,
        selectedVideoDevice
    );
    const [camera, setCamera] = useState<string>('');
    const closeQrScanner = useNavigateBack({
        failRoute: props.failRoute ? props.failRoute : '/connections'
    });
    const {ref} = useZxing({
        paused: !selectedVideoDevice,
        deviceId: selectedVideoDevice,
        onResult: processQRCode,
        constraints: {video: getConstraints()}
    });
    const tryPairing = useParing(props.connectionsModel, props.iomManager.requestManager, () =>
        setError(i18n.t('errors.connection.invitationNotValid'))
    );

    /**
     * Camera starting/restarting effect with delay
     */
    useEffect(() => {
        async function delayStart() {
            await new Promise(res => setTimeout(res, 1000));
            setCamera(selectedVideoDevice);
        }
        if (selectedVideoDevice !== '') {
            if (camera !== '' && camera !== selectedVideoDevice) {
                delayStart().catch(err => console.error(err));
            } else {
                setCamera(selectedVideoDevice);
            }
        }
    }, [selectedVideoDevice]);

    /**
     * Showing error to the user
     * @param error translated string
     */
    function setError(error: string) {
        setNotificationType(NOTIFICATION.Error);
        setNotificationMessage(error);
    }

    /**
     * qr code result text is a link in string format
     * @param result
     */
    async function processQRCode(result: Result | undefined | null) {
        if (result) {
            const cam = camera;
            setCamera('');
            const success = await tryPairing(result.getText());
            if (success) {
                closeQrScanner();
            } else {
                setCamera(cam);
            }
        }
    }

    function toggleCameraDirection() {
        if (
            videoDeviceInformation.isMultiDirectional &&
            videoDeviceInformation.availableFront &&
            videoDeviceInformation.availableBack
        ) {
            changeVideoDeviceId(
                videoDeviceInformation.availableBack === selectedVideoDevice
                    ? videoDeviceInformation.availableFront
                    : videoDeviceInformation.availableBack
            );
        }
    }

    /**
     * get camera constraints from available device information
     * @returns MediaTrackConstraints
     */
    // eslint-disable-next-line no-undef
    function getConstraints(): MediaTrackConstraints {
        let facingMode = undefined;
        if (
            !isIPhone13 &&
            videoDeviceInformation.currentDirection &&
            videoDeviceInformation.isMultiDirectional &&
            videoDeviceInformation.availableFront &&
            videoDeviceInformation.availableBack
        ) {
            facingMode =
                videoDeviceInformation.currentDirection === 'front' ? 'user' : 'environment';
        }
        return {
            deviceId: camera === '' ? undefined : camera,
            facingMode: facingMode
        };
    }

    const readerClassNames: string[] = ['qr-reader'];
    if (isMobile) {
        if (videoDeviceInformation.isMultiDirectional) {
            readerClassNames.push('is-mobile-and-has-multi-directional-camera');
        } else {
            readerClassNames.push('is-mobile');
        }
    } else if (videoDeviceSelector !== undefined) {
        readerClassNames.push('has-switch');
    }

    return (
        <div className="qr-reader-container">
            {camera !== '' && camera === selectedVideoDevice && (
                <video ref={ref} className={readerClassNames.join(' ')} />
            )}
            {(camera === '' || camera !== selectedVideoDevice) && (
                <CircularProgress className="qr-reader-loading" />
            )}
            {isMobile && videoDeviceInformation.isMultiDirectional && (
                <IconButton className="camera-capture-switch" onClick={toggleCameraDirection}>
                    <FlipCameraIosOutlined />
                </IconButton>
            )}
            {!isMobile && videoDeviceSelector}
        </div>
    );
}
