import type {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import AssignmentIcon from '@mui/icons-material/Assignment.js';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile.js';
import CameraAltIcon from '@mui/icons-material/CameraAlt.js';
import ThermostatIcon from '@mui/icons-material/Thermostat.js';
import WaterDropIcon from '@mui/icons-material/WaterDrop.js';
import MenuBookIcon from '@mui/icons-material/MenuBook.js';

import CarouselItem from '@/components/carousel/CarouselItem.js';

export const AddItemType = {
    questionnaire: 'questionnaire',
    document: 'document',
    image: 'image',
    bodyTemperature: 'bodyTemperature',
    wbc: 'wbc',
    diary: 'diary'
} as const;

export const AddItemTypes = Object.values(AddItemType);

const icon: Record<keyof typeof AddItemType, ReactElement> = {
    questionnaire: <AssignmentIcon />,
    document: <InsertDriveFileIcon />,
    image: <CameraAltIcon />,
    bodyTemperature: <ThermostatIcon />,
    wbc: <WaterDropIcon />,
    diary: <MenuBookIcon />
} as const;

export default function AddItem(props: {
    type: (typeof AddItemType)[keyof typeof AddItemType];
    onClick: () => void;
}): ReactElement {
    const i18n = useTranslation();
    return (
        <CarouselItem
            icon={icon[props.type]}
            text={i18n.t(`home.carousel.${props.type}`)}
            onClick={props.onClick}
        />
    );
}
