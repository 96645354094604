import type {ChangeEvent, RefObject} from 'react';
import {useRef, type ReactElement, useContext, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {isMobile} from 'react-device-detect';

import ImageCapturePopup from '@refinio/one.ui/lib/ui/views/imageCapture/ImageCapturePopup.js';
import type {PopupMenuEntry} from '@refinio/one.ui/lib/ui/components/popupMenu/PopupMenu.js';
import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {Person} from '@refinio/one.core/lib/recipes.js';

import {MenuContext} from '@/context/MenuContext.js';
import {MENU_ENTRY} from '@/components/popupMenu/PopupMenu.js';
import CarouselList from '@/components/carousel/CarouselList.js';
import AddItem, {AddItemType, AddItemTypes} from './AddItem.js';
import './CarouselMenu.css';

type MENU_ENTRY_VALUES = (typeof MENU_ENTRY)[keyof typeof MENU_ENTRY];
export type QuestionnaireMenu = {entry: MENU_ENTRY_VALUES; name: string};

// questionnaire popup menu default settings
const defaultQuestionnaireMenu: QuestionnaireMenu[] = [
    {entry: MENU_ENTRY.ExampleQuestionnaire, name: 'Example Questionnaire'},
    {entry: MENU_ENTRY.OnboardingPatientQuestionnaire, name: 'onboarding_patient'},
    {entry: MENU_ENTRY.GeneralQuestionnaireShort, name: 'General Questionnaire (short)'},
    {entry: MENU_ENTRY.WhpQuestionnaire, name: 'WHP Follow Up Visit From 1'},
    {
        entry: MENU_ENTRY.CervicalCancerScreeningAndTreatmentRegisterQuestionnaire,
        name: 'cervical_cancer_screeninc_and_treatment_register'
    },
    {entry: MENU_ENTRY.ResclueQuestionnaire, name: 'resclue'},
    {
        entry: MENU_ENTRY.IFCPC_colposcopic_terminology_cervix,
        name: '2011_IFCPC_colposcopic_terminology_cervix'
    },
    {
        entry: MENU_ENTRY.ColposcopyExaminationRecord,
        name: 'colposcopy_examination_record'
    },
    {
        entry: MENU_ENTRY.GeneralFeedbackQuestionnaire,
        name: 'general_feedback_edda'
    },
    {
        entry: MENU_ENTRY.SpecificFeedbackQuestionnaire,
        name: 'specific_feedback_edda'
    }
];

// image popup menu settings
const imageMenuEntries = [MENU_ENTRY.SELECT_IMAGES, MENU_ENTRY.TAKE_IMAGE];

/**
 *
 * @param props.onDocumentSubmit
 * @param props.dataOwner Optional. Default instance owner. When provided will save data with that id as owner
 * @param props.className Optional
 * @param props.title Optional
 * @param props.questionnaireMenu Optional Default @see defaultQuestionnaireMenu
 * @returns
 */
export default function CarouselMenu(props: {
    onDocumentSubmit: (file: File) => void | Promise<void>;
    dataOwner?: SHA256IdHash<Person>;
    className?: string;
    title?: string | undefined;
    questionnaireMenu?: QuestionnaireMenu[];
}): ReactElement {
    const questionnaireMenu = props.questionnaireMenu
        ? props.questionnaireMenu
        : defaultQuestionnaireMenu;
    const navigate = useNavigate();
    // popup menu control
    const {
        setMenuEntries,
        selectMenuEntry,
        isOpen,
        setMenuReference,
        setMenuClassName,
        setMenuAnchorOrigin
    } = useContext(MenuContext);

    // carousel references maping for popup menu
    const questionnaireItemRef = useRef<HTMLDivElement>(null);
    const imageItemRef = useRef<HTMLDivElement>(null);
    const documentItemRef = useRef<HTMLDivElement>(null);
    const refs: Partial<
        Record<(typeof AddItemType)[keyof typeof AddItemType], RefObject<HTMLDivElement>>
    > = {
        [AddItemType.questionnaire]: questionnaireItemRef,
        [AddItemType.image]: imageItemRef,
        [AddItemType.document]: documentItemRef
    } as const;

    // carousel popup menu entries maping
    const entriesType: Partial<
        Record<(typeof AddItemType)[keyof typeof AddItemType], Array<PopupMenuEntry>>
    > = {
        [AddItemType.questionnaire]: questionnaireMenu.map(qm => qm.entry),
        [AddItemType.image]: imageMenuEntries
    } as const;

    // camera
    const [showCamera, setShowCamera] = useState<boolean>(false);

    function toggleCamera() {
        setShowCamera((s: boolean) => !s);
    }

    // file/s
    const imageRef = useRef<HTMLInputElement>(null);
    const fileRef = useRef<HTMLInputElement>(null);

    function questionnaireRoute(name: string): void {
        if (props.dataOwner) {
            if (name === 'EQ5D3LQuestionnaire') {
                navigate(`/questionnaire/${props.dataOwner}/${name}/new`);
                return;
            }
            navigate(`/questionnaire/${props.dataOwner}/new?questionnaires=${name}`);
            return;
        }

        if (name === 'EQ5D3LQuestionnaire') {
            navigate(`/questionnaire/${name}/new`);
            return;
        }

        navigate(`/questionnaire/new?questionnaires=${name}`);
    }

    // dynamic popup menu event with html element reference control and menu selection control
    function openMenu(addItemType: (typeof AddItemType)[keyof typeof AddItemType]): void {
        const ref = refs[addItemType];
        const entries = entriesType[addItemType];
        if (!ref || !entries || entries.length === 0) {
            return;
        }

        setMenuReference(ref.current);
        setMenuAnchorOrigin({horizontal: 'center', vertical: 'center'});
        setMenuClassName('app-bar-cnt-add-menu');
        setMenuEntries(entries);
        selectMenuEntry(() => (entrySelected: string) => {
            setMenuClassName('app-bar-cnt-menu');
            isOpen(false);
            switch (addItemType) {
                case AddItemType.questionnaire:
                    if (entrySelected === MENU_ENTRY.QuestionnaireEQ5D3L) {
                        questionnaireRoute('EQ5D3LQuestionnaire');
                    } else {
                        handleQuestionnaireMenuSelect(entrySelected);
                    }
                    break;
                case AddItemType.image:
                    handleImageMenuSelect(entrySelected);
                    break;
            }
        });
        isOpen(true);
    }

    function handleQuestionnaireMenuSelect(entrySelected: string) {
        const questionaireName = questionnaireMenu.find(qm => qm.entry === entrySelected);
        if (questionaireName) {
            questionnaireRoute(questionaireName.name);
        } else {
            console.error(`no questionnaire name found for entrySelected ${entrySelected}`);
        }
    }

    function handleImageMenuSelect(entrySelected: string) {
        switch (entrySelected) {
            case MENU_ENTRY.SELECT_IMAGES:
                if (null !== imageRef.current) {
                    imageRef.current.click();
                }
                break;
            case MENU_ENTRY.TAKE_IMAGE:
                toggleCamera();
                break;
        }
    }

    function handleClick(addItemType: (typeof AddItemType)[keyof typeof AddItemType]): void {
        switch (addItemType) {
            case AddItemType.document:
                if (!fileRef.current) {
                    return;
                }

                fileRef.current.click();
                break;
            case AddItemType.diary:
                navigate(`/diary/${props.dataOwner ? `${props.dataOwner}/` : ''}create`);
                break;
            case AddItemType.wbc:
                navigate(`/wbc/${props.dataOwner ? `${props.dataOwner}/` : ''}create`);
                break;
            case AddItemType.bodyTemperature:
                navigate(`/bodyTemperature/${props.dataOwner ? `${props.dataOwner}/` : ''}create`);
                break;
            case AddItemType.image:
                if (isMobile) {
                    if (!imageRef.current) {
                        return;
                    }

                    imageRef.current.click();
                    return;
                } else {
                    openMenu(addItemType);
                }
                break;
            default:
                openMenu(addItemType);
        }
    }

    async function getHandleInputChange(event: ChangeEvent<HTMLInputElement>) {
        if (event === null || event.target === null || event.target.files === null) {
            return;
        }
        if (event.target.files && event.target.files.length > 0) {
            await postFile([...event.target.files]);
        }
    }

    async function postFile(files: File[]): Promise<void> {
        for (const file of files) {
            await props.onDocumentSubmit(file);
        }
    }

    // CarouselList dynamic renrering
    return (
        <>
            {showCamera && (
                <ImageCapturePopup
                    onFileChange={(file: File) => postFile([file])}
                    active={showCamera}
                    onClose={toggleCamera}
                />
            )}
            <input
                ref={imageRef}
                type="file"
                style={{display: 'none'}}
                accept="image/*"
                onChange={getHandleInputChange}
                multiple
            />
            <input
                ref={fileRef}
                type="file"
                style={{display: 'none'}}
                onChange={getHandleInputChange}
                multiple
            />
            <CarouselList
                className={`carousel-menu${props.className ? ` ${props.className}` : ''}`}
                title={props.title}
            >
                {AddItemTypes.map(addItemType => (
                    <div key={addItemType} ref={refs[addItemType] ? refs[addItemType] : undefined}>
                        <AddItem type={addItemType} onClick={() => handleClick(addItemType)} />
                    </div>
                ))}
            </CarouselList>
        </>
    );
}
