import type {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {CircularProgress} from '@mui/material';

import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type DocumentModel from '@refinio/one.models/lib/models/DocumentModel.js';

import AvatarPreview from '@/components/avatarPreview/AvatarPreview.js';
import {useMePreview} from '@/hooks/contact/someoneHooks.js';
import CarouselMenu from '@/components/carousel/CarouselMenu.js';
import './Home.css';

export default function Home(props: {
    leuteModel: LeuteModel;
    documentModel: DocumentModel;
}): ReactElement {
    const i18n = useTranslation();
    return (
        <div className="home-container">
            <Me leuteModel={props.leuteModel} />
            <CarouselMenu
                title={i18n.t('home.carouselTitle')}
                onDocumentSubmit={async file =>
                    props.documentModel.addDocument(await file.arrayBuffer(), file.type, file.name)
                }
            />
        </div>
    );
}

function Me(props: {leuteModel: LeuteModel}): ReactElement {
    const me = useMePreview(props.leuteModel);
    const i18n = useTranslation();

    if (!me) {
        return <CircularProgress />;
    }

    return (
        <div className="me-preview">
            <AvatarPreview src={me.avatar} />
            {i18n.t('home.hello')} {me.name}!
        </div>
    );
}
