import {useTheme} from '@mui/material/styles';
import type {ReactElement} from 'react';
import {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';

import type {OneObjectTypes} from '@refinio/one.core/lib/recipes.js';
import type {SHA256Hash} from '@refinio/one.core/lib/util/type-checks.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type TopicModel from '@refinio/one.models/lib/models/Chat/TopicModel.js';
import type {Topic} from '@refinio/one.models/lib/recipes/ChatRecipes.js';
import AppBarFullScreenPopup from '@refinio/one.ui/lib/ui/components/appBar/AppBarFullScreenPopup.js';

import SelectableTopicList from './SelectableTopicList.js';

/**
 * @param props.leuteModel
 * @param props.topicModel
 * @param props.sharedEntry
 * @param props.isOpen
 * @param props.onClose useCallback cached function, if you do not want endless rerenders
 * @returns
 */
export function ShareJournalEntryPopUp(props: {
    leuteModel: LeuteModel;
    topicModel: TopicModel;
    sharedEntry: SHA256Hash<OneObjectTypes> | undefined;
    isOpen: boolean;
    onClose: () => void;
}): ReactElement {
    const [shareWithTopics, setShareWithTopics] = useState<Topic[]>([]);
    const i18n = useTranslation();
    const theme = useTheme();

    const shareEntry = useCallback(
        async (topics: Topic[]) => {
            if (props.sharedEntry === undefined) {
                // should not happen
                throw Error('Unknown share entry');
            }

            for (let i = 0; i < topics.length; i++) {
                const topicRoom = await props.topicModel.enterTopicRoom(topics[i].id);
                await topicRoom.sendMesageWithAttachmentAsHash('', [props.sharedEntry]);
            }
        },
        [props.sharedEntry, props.topicModel]
    );

    const close = useCallback(() => {
        setShareWithTopics([]);
        props.onClose();
    }, [props.onClose]);

    const share = useCallback(async () => {
        if (props.sharedEntry === undefined) {
            return;
        }
        await shareEntry(shareWithTopics);
        close();
    }, [close, props.sharedEntry, shareEntry, shareWithTopics]);

    return (
        <>
            {props.isOpen && (
                <AppBarFullScreenPopup
                    background={theme.palette.background.default}
                    className="journal-share-list"
                    mode="edit"
                    onClose={close}
                    onFinish={share}
                    title={i18n.t('common.edit')}
                >
                    <>
                        <div className="cnt-title">{i18n.t('journal.entryMenu.sharing')}</div>
                        <SelectableTopicList
                            leuteModel={props.leuteModel}
                            topicModel={props.topicModel}
                            onChange={setShareWithTopics}
                        />
                    </>
                </AppBarFullScreenPopup>
            )}
        </>
    );
}
