import {useCallback, useEffect, useState} from 'react';

import type {CreationTime} from '@refinio/one.models/lib/recipes/MetaRecipes.js';
import type {ChatMessage} from '@refinio/one.models/lib/recipes/ChatRecipes.js';
import type {SHA256Hash, SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type ChannelManager from '@refinio/one.models/lib/models/ChannelManager.js';
import type {OneObjectTypes, Person} from '@refinio/one.core/lib/recipes.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type {LinkedListEntry} from '@refinio/one.models/lib/recipes/ChannelRecipes.js';

import OneObjectCache from '@/root/chat/cache/OneObjectCache.js';
import RawChannelEntriesCache from '@/root/chat/cache/RawChannelEntriesCache.js';
import ChatAttachmentCache from '@/root/chat/cache/ChatAttachmentCache.js';

export type ChatAttachmentsInfo = {
    cachedOneObject: OneObjectTypes | undefined; // undefined when ChatAttachmentCache is still loading the object
    hash: SHA256Hash;
    originalHash?: SHA256Hash;
};

export type CachedChatMessage = {
    date: Date;
    isMe: boolean;
    author: string;
    message: string;
    attachments: ChatAttachmentsInfo[];
    authorIdHash: SHA256IdHash<Person>;
    messageHash: SHA256Hash<ChatMessage>;
    channelEntryHash: SHA256Hash<LinkedListEntry>;
    creationTimeHash: SHA256Hash<CreationTime>;
};

async function loadMyIdHash(leuteModel: LeuteModel): Promise<SHA256IdHash<Person>> {
    const me = await leuteModel.me();
    const myProfile = await me.mainProfile();
    return myProfile.personId;
}

/**
 * Load chat messages in a manner, that it is easy to use in a synchronous context.
 *
 * This function will take care of everything like updating the messages when new ones appear.
 *
 * @param channelManager - The channelmanager used to obtain elements from the channel.
 * @param leuteModel - The leute model used to obtain person information (like the name)
 * @param channelId - The channelId of the channel.
 * @param onAsyncError - callback called when an async error happens.
 * @param batchSize - Number of elements loaded in one go. To load more you have to call loadNextBatch().
 */
export default function useChatMessages(
    channelManager: ChannelManager,
    leuteModel: LeuteModel,
    channelId: string | undefined,
    onAsyncError: (message: any) => void,
    batchSize: number = 25
): [CachedChatMessage[], () => void, boolean, () => void] {
    const [messagesCache, setMessagesCache] = useState<CachedChatMessage[]>([]);
    const [loadNextBatch, setLoadNextBatch] = useState<() => void>(() => () => {});
    const [newMessageTrigger, setNewMessageTrigger] = useState(false);
    const [forceReloadMessages, setForceReloadMessages] = useState<boolean>(false);

    const forceReload = useCallback(() => {
        setForceReloadMessages(v => !v);
    }, [setForceReloadMessages]);

    // ######## Load my id hash before

    useEffect(
        function () {
            console.log('useEffect - init', channelId);

            const shutdownFunctions: (() => void)[] = [];

            (async () => {
                if (channelId === undefined) {
                    return;
                }

                const myIdHash = await loadMyIdHash(leuteModel);

                // ######## ATTACHMENTS ########

                const attachmentCache = new ChatAttachmentCache();
                shutdownFunctions.push(attachmentCache.onError(onAsyncError));
                shutdownFunctions.push(
                    attachmentCache.onUpdate(() => {
                        console.log('######## Attachment forceRender ########');
                    })
                );
                shutdownFunctions.push(attachmentCache.shutdown.bind(attachmentCache));

                // ######## CHAT MESSAGE ########

                const messageCache = new OneObjectCache<ChatMessage>(['ChatMessage']);
                shutdownFunctions.push(messageCache.onError(onAsyncError));
                shutdownFunctions.push(
                    messageCache.onUpdate((_objHash, obj) => {
                        if (obj.attachments && !obj.thumbnails) {
                            for (const attachment of obj.attachments) {
                                attachmentCache.monitorAttachment(attachment);
                            }
                        } else if (obj.thumbnails) {
                            for (const thumbnail of obj.thumbnails) {
                                attachmentCache.monitorAttachment(thumbnail);
                            }
                        }
                    })
                );
                shutdownFunctions.push(messageCache.shutdown.bind(messageCache));

                // ######## CHAT MESSAGE MERKLE TREE ########

                const rawChannelEntriesCache = new RawChannelEntriesCache(
                    channelManager,
                    channelId,
                    null,
                    batchSize
                );
                shutdownFunctions.push(rawChannelEntriesCache.onError(onAsyncError));
                shutdownFunctions.push(rawChannelEntriesCache.onUpdate(updateMessageCache));
                shutdownFunctions.push(
                    rawChannelEntriesCache.shutdown.bind(rawChannelEntriesCache)
                );
                setLoadNextBatch(() => () => {
                    rawChannelEntriesCache.loadNextBatch();
                });

                // ######## Assemble the CachedChatMessage ########

                async function updateMessageCache(newMessages: boolean) {
                    console.log('updateMessageCache', rawChannelEntriesCache.cachedEntries());

                    // #### Build the message cache and load the messages
                    const newMessagesCache: CachedChatMessage[] = [];
                    for (const rawLinkedListEntry of rawChannelEntriesCache.cachedEntries()) {
                        // Trigger the async load of the message
                        const chatMessage =
                            await messageCache.queryOrLoadObjectIntoCacheWithRuntimeCheck(
                                rawLinkedListEntry.dataHash
                            );

                        const message: CachedChatMessage = {
                            date: new Date(rawLinkedListEntry.creationTime),
                            isMe: chatMessage.sender === myIdHash,
                            get author(): string {
                                return (
                                    leuteModel.getPersonName(this.authorIdHash) || this.authorIdHash
                                );
                            },
                            message: chatMessage.text,
                            get attachments(): ChatAttachmentsInfo[] {
                                if (chatMessage.attachments === undefined) {
                                    return [];
                                }

                                if (chatMessage.attachments && chatMessage.thumbnails) {
                                    return chatMessage.thumbnails.map((thumbnailIdHash, index) => {
                                        const attachmentHash = chatMessage.attachments
                                            ? chatMessage.attachments[index]
                                            : undefined;
                                        if (!attachmentCache.initialized()) {
                                            return {
                                                cachedOneObject: undefined,
                                                hash: thumbnailIdHash,
                                                isThumbnail: false,
                                                originalHash: undefined
                                            };
                                        }
                                        return {
                                            cachedOneObject:
                                                attachmentCache.queryAttachment(thumbnailIdHash),
                                            hash: thumbnailIdHash,
                                            isThumbnail: true,
                                            originalHash: attachmentHash
                                        };
                                    });
                                }

                                return chatMessage.attachments.map(attachmentIdHash => {
                                    return {
                                        cachedOneObject:
                                            attachmentCache.queryAttachment(attachmentIdHash),
                                        hash: attachmentIdHash
                                    };
                                });
                            },
                            authorIdHash: chatMessage.sender,
                            messageHash: rawLinkedListEntry.dataHash as SHA256Hash<ChatMessage>,
                            channelEntryHash: rawLinkedListEntry.channelEntryHash,
                            creationTimeHash: rawLinkedListEntry.creationTimeHash
                        };

                        newMessagesCache.push(message);
                    }

                    console.log('######## setMessagesCache render ######## ');
                    setMessagesCache(newMessagesCache);
                    if (newMessages) {
                        console.log('######## setNewMessageTrigger render ########');
                        setNewMessageTrigger(v => !v);
                    }
                }
                // Start loading the first batch
                rawChannelEntriesCache.init();
            })().catch(onAsyncError);

            return () => {
                console.log('useEffect - shutdown');
                shutdownFunctions.forEach(listener => listener());
            };
        },
        [forceReloadMessages, batchSize, channelId, channelManager, leuteModel, onAsyncError]
    );

    // ######## On each render rebuild the returned array from the caches ########

    return [messagesCache, loadNextBatch, newMessageTrigger, forceReload];
}
