import type {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {Route, Routes} from 'react-router-dom';

import {APP_BAR_MODE} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';
import PageNotFound from '@/components/PageNotFound.js';
import AppBarSettings from '@/components/appBar/AppBarSettings.js';
import {useNavigateBack} from '@/hooks/navigation.js';
import OwnSignKeys from './own/OwnSignKeys.js';

export default function SignKeysRouter(_props: {}): ReactElement {
    const goBack = useNavigateBack();
    const i18n = useTranslation();

    return (
        <Routes>
            <Route
                path="own"
                element={
                    <>
                        <AppBarSettings
                            mode={APP_BAR_MODE.CHEVRON}
                            leftBtnCallback={goBack}
                            title={i18n.t('leute.signKey.title')}
                        />
                        <OwnSignKeys />
                    </>
                }
            />
            <Route element={<PageNotFound />} />
        </Routes>
    );
}
