import {useEffect, type ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import Clipboard from 'react-clipboard.js';

import Button from '@mui/material/Button/Button.js';
import Avatar from '@mui/material/Avatar/Avatar.js';
import TextField from '@mui/material/TextField/TextField.js';
import ListItemButton from '@mui/material/ListItemButton/ListItemButton.js';
import ListItemIcon from '@mui/material/ListItemIcon/ListItemIcon.js';
import FileCopyIcon from '@mui/icons-material/ContentCopyOutlined.js';

import {ONBOARDING_PROGRESS} from '@/components/onboarding/Onboarding.js';
import leute_logo from '@/resources/images/leute-logo.svg';
import './IdentityExportKeysCodePrompt.css';

/**
 *
 * @param props.code export code
 * @param props.onDone
 * @param props.currentStep
 * @param props.logoSrc Optional. Default leute logo src.
 * @returns
 */
export default function IdentityExportKeysCodePrompt(props: {
    code: string;
    onDone: () => void;
    currentStep: number;
    logoSrc?: string;
}): ReactElement {
    const i18n = useTranslation();

    useEffect(() => {
        async function keyDownHandler(event: KeyboardEvent): Promise<void> {
            if (event.key === 'Enter') {
                if (navigator.clipboard) {
                    // new API
                    await navigator.clipboard.writeText(props.code);
                } else {
                    // old API
                    document.execCommand('copy', true, props.code);
                }
                props.onDone();
            }
        }

        document.addEventListener('keyup', keyDownHandler);

        return () => {
            document.removeEventListener('keyup', keyDownHandler);
        };
    }, []);

    return (
        <div className="information-prompt-container identity-export-keys-code-display">
            <div className="information-prompt">
                <img
                    className="logo"
                    alt="app-logo"
                    src={props.logoSrc ? props.logoSrc : leute_logo}
                />
                <div className="question h1">{i18n.t('onboarding.informationPrompt.saveKeys')}</div>
                <div className="question h2">
                    {i18n.t('onboarding.informationPrompt.saveKeysDescription')}
                </div>
                <div className="question h3">
                    {i18n.t('onboarding.informationPrompt.saveKeysDisclamier')}
                </div>
            </div>
            <div className="export-code">
                <TextField
                    className="txt-field"
                    variant="standard"
                    value={props.code}
                    disabled={true}
                />
                <Clipboard component="div" data-clipboard-text={props.code}>
                    <ListItemButton key="fileCopy" className="copyIcon">
                        <ListItemIcon>
                            <Avatar className="icon-avatar-wrapper FileCopy">
                                <FileCopyIcon className="icon-avatar" />
                            </Avatar>
                        </ListItemIcon>
                    </ListItemButton>
                </Clipboard>
            </div>
            <div className="information-handler">
                <div className="set-information-container">
                    <div className="left-buttons"></div>
                    <div className="progress">
                        {props.currentStep + '/' + (Object.keys(ONBOARDING_PROGRESS).length - 2)}
                    </div>
                    <div className="right-buttons">
                        <Button
                            color="primary"
                            variant="contained"
                            className="body ok-btn"
                            onClick={props.onDone}
                        >
                            {i18n.t('buttons.common.startOne')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
